<template>
	<div :class="$style['task-list-item']" :style="{ borderColor: statusColor }">
		<table>
			<tr>
				<td class="title">名称：</td>
				<td>{{data.orderName}}</td>
			</tr>
			<tr>
				<td class="title">客户标识：</td>
				<td>{{data.customerCode}}</td>
			</tr>
			<tr>
				<td class="title">编号：</td>
				<td class="conte-item">{{data.orderCode}}</td>
			</tr>
			<tr>
				<td class="title">任务单：</td>
				<td class="conte-item">{{data.taskCode}}</td>
			</tr>
			<tr>
				<td class="title">实验人员：</td>
				<td class="conte-item">{{(data.otherInfo&&data.otherInfo.orderExamUser)||data.experimenter}}</td>
			</tr>
			<tr>
				<td class="title">交付方式：</td>
				<td class="conte-item">{{data.delivery_type||'--'}}</td>
			</tr>
		</table>
		<div class="order-status" :style="{color:statusColor}">{{data.orderStatus}}</div>

	</div>
</template>
<script>
export default {
  props:{
    data:{
      type:Object,
      default:function (){
        return {}
      }
    }
  },
  computed:{
    statusColor (){
      return this.data.billStatusEnum === 'BILL_COMPLETED' ? '#ADADAD' : "#409EFF"
    }
    /*样品不需要加急和逾期标识*/
    // showColor () {
    //   return this.data.urgent ? "#FF2D2D" : (this.data.overDue ? "#F7B500" : this.statusColor)
    // }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.task-list-item{
	background-color: #fff;
  padding: 15px 10px;
  border-left: solid 5px @main-color;
	border-radius: 5px;
	display:flex;
	justify-content:space-between;
	font-size:@font-size-tip;
	:global{
		.conte-item{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
		}
		.seconed{
			padding-left: 5px
		}
		.first-line{
			display: flex;
			justify-content:space-between;
		}
		.title{
			color:@font-of-grey
		}
		.order-status{

		}
	}
	table{
		td{
			text-align:left;
			padding:5px 0;
		}
	}
}
</style>