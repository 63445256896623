// 获取用户是否有当前权限。user指用户获取到的权限，permission指当前功能对应的权限名称
export function hasPermissions(user, permission) {
  return user.includes(permission)
}
let invoicePermission = ['PERM_B_M', 'PERM_B_06']
let settlementPermission = ['PERM_B_M', 'PERM_B_07']
  //当前用户是否拥有开票权限,permissions为用户权限
export function hasePermissionsOfInvoice(permissions) {
  let newArr = permissions.filter(item => {
    return invoicePermission.includes(item)
  })
  return !!newArr.length
}
//当前用户是否拥有结算权限,permissions为用户权限
export function hasePermissionsOfSettlement(permissions) {
  let newArr = permissions.filter(item => {
    return settlementPermission.includes(item)
  })
  return !!newArr.length
}