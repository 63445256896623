<template>
  <pt-sample-list class="pt-sample-list__withData"  @load="load" ref="loadmore" :billId="billId" :selectSample="selectSample" :allSelected="allSelected" :data="data" @getSelected="getSelected"></pt-sample-list>
</template>

<script>
import PtSampleList from './index'
export default {
  name: 'pt-task-list__withData',
  components: {
    'pt-sample-list': PtSampleList
  },
  props:{
    billId:String,
    selectSample:Boolean,
		allSelected:Boolean,
		taskId:String
  },
  data (){
    return{
      data:[],
      totalNumber:0
    }
	},
	watch:{
		selectSample(val){
			this.loadmore.refresh(false, true)
		}
	},
	computed:{
    loadmore () {
      return this.$refs.loadmore;
    }
	},
  methods:{
    getSelected (val){
      this.$emit('getSelected', val)
    },
    showRetry () {
      this.loadmore.showRetry();
    },
    refresh (val, row){
      this.loadmore.refresh(val, row)
    },
    load (page, next){
      let param = {
        example:{
        },
        pageable: {
          page: page,
          size: 20
        }
			};
			if(this.billId){
				param.example.billId=this.billId
			}

			if(this.taskId){
				param.example.taskId=this.taskId
			}
			if(this.selectSample){
				param.example.orderStatusEnum="ORDER_RECEIVED"
			}
      this.$store
        .dispatch("getSampleList", param)
        .then((response) => {
          let { data, totalNumber } = response;
          this.data = data
          this.totalNumber = totalNumber
          next(data, totalNumber);
        })
        .catch((error) => {
          this.loadmore.showRetry();
          this.$toast.error(error);
        });
    }
  },
  created (){
  }
}
</script>
