<template>
  <wt-loadmore ref="loadmore" :on-pulldown="load" :on-pullup="load" :class="$style['task-list']">
    <template slot-scope="{ data }">
			<cube-checkbox-group v-model="checkList" v-if="selectSample">
				<cube-checkbox :option="item.id" v-for="(item, index) in data" :key="index">
					<div  :class="$style['list-item']">
					<item :data="item" @click.native="goDetail(item.id)"></item>
					</div>
					</cube-checkbox>
			</cube-checkbox-group>
      <div
        :class="$style['list-item']"
        v-for="(item, index) in data"
        :key="index"
				v-else
      >
        <item :data="item" @click.native="goDetail(item.id)"></item>
      </div>
    </template>
  </wt-loadmore>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-sample-list'
import item from "./item"
export default {
  name: 'pt-sample-list',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    loadmore () {
      return this.$refs.loadmore;
    }
  },
  components:{
    item
  },
  props:{
    billId:String,
    selectSample:Boolean,
    allSelected:Boolean,
    data:{
      type:Array,
      default:function (){
        return []
      }
    }
  },
  data (){
    return{
      checkList:[]
    }
  },
  watch:{
    allSelected (val){
      if(val){
        this.checkList = this.data.map(item => {
          return item.id
        })
      }else{
        this.checkList = []
      }
    },
    checkList:{
      handler:function (val){
        this.$emit('getSelected', val)
      }
    }
  },
  methods:{
    goDetail (id){
      this.$router.push(`/sample-detail/${id}`)
    },
    load (page, next){
      this.$emit('load', page, next)
    },
    showRetry () {
      this.loadmore.showRetry();
    },
    refresh (val, row){
      this.loadmore.refresh(val, row)
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.task-list{
	margin-top: 20px;
	:global{
		.cube-checkbox-label{
			flex:1
		}
	}
}
.list-item{
	padding-bottom: 20px;
	:global{
	}
}
</style>
